import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import getBaseUrl from "../Api/BaseUrl";

AOS.init();

/**
 * How It Works Page
 * */

const HowItWorks = () => {
  const [authState, setAuthState] = useState(() =>
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY))
  );
  const isAuthenticated = authState && authState.isAuthenticated === true;
  const authRedirectUrl = `${getBaseUrl()}/patient/treatment-plans`;
  const authConsultationRedirectUrl = `${getBaseUrl()}/patient/consultations`;

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedAuthState = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY)
      );
      setAuthState(updatedAuthState);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  // Function to smoothly scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Helmet>
        <title>Balanced | How It Works</title>
      </Helmet>
      <Header />
      <div className="how-it-works">
        <div className="how-it-works-hero-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="how-it-works-hero-section-content">
                  <h2 className="how-it-works-hero-section-content-title">
                    How It Works
                  </h2>
                  <p className="how-it-works-hero-section-content-description">
                    At Balanced, we believe that managing your health should be
                    simple, discreet and customised to fit your life. Our online
                    platform provides a range of treatments designed to address
                    a variety of health needs.
                  </p>
                  <ul className="list-unstyled how-it-works-hero-section-content-list">
                    <li className="d-flex align-items-center justify-content-center">
                      <img
                        src={require("..//assets/images/checkmark.png")}
                        alt="checkmark"
                      />
                      <p className="mb-0 ms-2">Identify Your Needs</p>
                    </li>
                    <li className="d-flex align-items-center justify-content-center">
                      <img
                        src={require("..//assets/images/checkmark.png")}
                        alt="checkmark"
                      />
                      <p className="mb-0 ms-2">Connect with Experts</p>
                    </li>
                    <li className="d-flex align-items-center justify-content-center">
                      <img
                        src={require("..//assets/images/checkmark.png")}
                        alt="checkmark"
                      />
                      <p className="mb-0 ms-2">Receive Tailored Care</p>
                    </li>
                  </ul>
                  <Link
                    to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                    onClick={scrollToTop}
                  >
                    <button className="ctabtn how-it-works-hero-section-content-btn">
                      Check Your Health
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="how-it-works-journey-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="how-it-works-journey-section-wrapper">
                  <div className="how-it-works-journey-section-content">
                    <h2
                      className="how-it-works-journey-section-content-title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Your Balanced Journey
                    </h2>
                    <p
                      className="how-it-works-journey-section-description"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Get started by taking our quick online quiz, designed to
                      understand your unique health needs and preferences. Once
                      completed, you’ll be connected with our expert medical
                      team for a personalized consultation to determine the most
                      suitable treatment for you.
                    </p>

                    <div className="how-it-works-journey-section-steps">
                      <div
                        className="how-it-work-journey-section-section-steps-step"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        <div className="step-number">1</div>
                        <div className="step-name">
                          Complete Health Questionnaire
                        </div>
                      </div>
                      <div
                        className="arrow mx-2"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        {/* ➔ */}
                        <img
                          src={require("..//assets/images/forward-arrow.png")}
                          alt="journey steps arrow"
                          width="40"
                          height="40"
                        />
                      </div>
                      <div
                        className="how-it-work-journey-section-section-steps-step"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        <div className="step-number">2</div>
                        <div className="step-name">
                          Connect with Medical Experts
                        </div>
                      </div>
                      <div
                        className="arrow mx-2"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        {/* ➔ */}
                        <img
                          src={require("..//assets/images/forward-arrow.png")}
                          alt="journey steps arrow"
                          width="40"
                          height="40"
                        />
                      </div>
                      <div
                        className="how-it-work-journey-section-section-steps-step"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        <div className="step-number">3</div>
                        <div className="step-name">Start Wellness Journey</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="how-it-works-process-details questionnaire">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 text-center image-wrapper"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img
                  src={require("..//assets/images/Default_Doctor_uncopped_dark_teal_color_mood_1.jpg")}
                  alt="Doctor"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6 how-it-works-process-details-content">
                <h3
                  className="how-it-works-process-details-title"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  Complete Health Questionnaire
                </h3>
                <ul className="how-it-works-process-details-list">
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    {" "}
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />{" "}
                    <p className="mb-0 ms-2">
                      Select from 10 Available Treatments
                    </p>
                  </li>
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    {" "}
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />{" "}
                    <p className="mb-0 ms-2">Share Your Health Background</p>
                  </li>
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    {" "}
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />{" "}
                    <p className="mb-0 ms-2">Create Your Health Profile</p>
                  </li>
                </ul>
                <Link
                  to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                  onClick={scrollToTop}
                >
                  <button
                    className="ctabtn how-it-works-process-details-btn"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Find My Treatment
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="how-it-works-process-details consultation">
          <div className="container">
            <div className="row">
              <div className="col-md-6 how-it-works-process-details-content">
                <h3
                  className="how-it-works-process-details-title"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  Connect with Medical Experts
                </h3>
                <ul className="how-it-works-process-details-list">
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />
                    <p className="mb-0 ms-2">
                      Schedule Your Phone Consultation
                    </p>
                  </li>
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />
                    <p className="mb-0 ms-2">Discuss Your Health Needs</p>
                  </li>
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />
                    <p className="mb-0 ms-2">
                      Explore Personalized Care Options
                    </p>
                  </li>
                </ul>
                <Link
                  to={
                    isAuthenticated ? authConsultationRedirectUrl : `/quizzes`
                  }
                  onClick={scrollToTop}
                >
                  <button
                    className="ctabtn how-it-works-process-details-btn"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Book A Consultation
                  </button>
                </Link>
              </div>
              <div
                className="col-md-6 text-center image-wrapper"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img
                  src={require("..//assets/images/Default_doctor_and_nurse_with_dark_teal_color_mood_0.jpg")}
                  alt="Doctor"
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="how-it-works-process-details questionnaire">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 text-center image-wrapper"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img
                  src={require("..//assets/images/mood_sleep.jpg")}
                  alt="Doctor"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6 how-it-works-process-details-content">
                <h3
                  className="how-it-works-process-details-title"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  Start Wellness Journey
                </h3>
                <ul className="how-it-works-process-details-list ">
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />
                    <p className="mb-0 ms-2">Choose Your Ideal Treatment</p>
                  </li>
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />
                    <p className="mb-0 ms-2">
                      Your Treatment is Packed with Care
                    </p>
                  </li>
                  <li
                    className="d-flex align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    <img
                      src={require("..//assets/images/checkmark.png")}
                      alt="checkmark"
                    />
                    <p className="mb-0 ms-2">
                      Secure Delivery Straight to Your Door
                    </p>
                  </li>
                </ul>
                <Link
                  to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                  onClick={scrollToTop}
                >
                  <button
                    className="ctabtn how-it-works-process-details-btn"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Find My Treatment
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="callToAction_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <CallToAction />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HowItWorks;
