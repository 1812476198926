import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import illustartion1 from "..//assets/images/yourhealthfirstillustration.svg";
import illustartion2 from "..//assets/images/honestcareillustration.svg";
import illustartion3 from "..//assets/images/personalizedcareillustration.svg";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getBaseUrl from "../Api/BaseUrl";

AOS.init();

/**
 *About Us Page
 */

function AboutUs() {
  const [authState, setAuthState] = useState(() =>
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY))
  );
  const isAuthenticated = authState && authState.isAuthenticated === true;
  const authRedirectUrl = `${getBaseUrl()}/patient/treatment-plans`;
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedAuthState = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY)
      );
      setAuthState(updatedAuthState);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  // Function to smoothly scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="aboutUs_page_wrapper">
      <Helmet>
        <title>Balanced | Story Behind Our Plaform</title>
      </Helmet>
      <Header />
      <div className="aboutUs_page">
        <div className="about-us-hero-section d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-grid">
                <div className="about-us-hero-section-content">
                  <h2 className="about-us-hero-section-content-title">
                    Balanced Health and <span> Wellness Platform</span>
                  </h2>
                  <p className="about-us-hero-section-content-description">
                    Welcome to Balanced, your trusted partner in personalised
                    health solutions. We specialise in treatments that enhance
                    various aspects of your daily well-being, from vitality and
                    weight management to cognitive health and skin care.
                    <br></br>
                    At Balanced, we harness a mix of cutting-edge medical
                    therapies, including peptides, traditional medications, and
                    non-prescription options, to offer tailored solutions that
                    meet your unique health goals.
                  </p>

                  <Link
                    className="ctaBtn about-us-btn"
                    to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                    onClick={scrollToTop}
                  >
                    Find your treatment
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-us-image-wrapper"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-story">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="our-story-content">
                  <p
                    className="our-story-content-subtitle"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Your Health, Our Commitment
                  </p>
                  <h3
                    className="our-story-content-title"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    We’re here to make healthcare simpler, more accessible, and
                    more personal. Join us on a journey toward a healthier, more
                    vibrant life — guided by innovative treatments and
                    compassionate support every step of the way.
                  </h3>
                  <Link
                    to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                    onClick={scrollToTop}
                  >
                    <button
                      className="ctaBtn our-story-btn"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Find your treatment
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="founders-section ">
          <div className="container">
            <h2
              className="founders-section-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our Founders
            </h2>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="founder-card">
                  <div className="founder-card-top">
                    <img
                      src={require("..//assets/images/Default_Doctor_uncopped_dark_teal_color_mood_0.jpg")}
                      alt="John Doe"
                      className="img-fluid founder-card-image mb-3"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    />
                    <div className="founder-card-top-info">
                      <h5
                        className="founder-card-name"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        John Doe
                      </h5>
                      <p
                        className="founder-card-role"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Founder
                      </p>
                    </div>
                  </div>
                  <p
                    className="founder-card-description"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Dr. John, the founder of the Balanced, embodies a passion
                    for healing and a commitment to excellence. With decades of
                    experience in the field, Dr. John envisioned a place where
                    patients could receive not just top-notch medical care but
                    also compassionate support and personalized attention.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="founder-card">
                  <div className="founder-card-top">
                    <img
                      src={require("..//assets/images/Default_Doctor_uncopped_dark_teal_color_mood_0.jpg")}
                      alt="Chris Doe"
                      className="img-fluid founder-card-image mb-3"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    />
                    <div className="founder-card-top-info">
                      <h5
                        className="founder-card-name"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Chris Doe
                      </h5>
                      <p
                        className="founder-card-role"
                        data-aos="fade-up"
                        data-aos-duration="800"
                      >
                        Co-Founder
                      </p>
                    </div>
                  </div>
                  <p
                    className="founder-card-description"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Dr. Chris, the co-founder of Balanced, brings a wealth of
                    expertise and a deep passion for patient-centered care. With
                    years of experience, Dr. Chris co-created the center with a
                    vision to provide exceptional healthcare that prioritizes
                    both clinical excellence and compassionate support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="story-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div
                  className="story-section-image"
                  data-aos="fade-up"
                  data-aos-duration="800"
                ></div>
              </div>
              <div className="col-md-6">
                <div className="story-section-content">
                  <p
                    className="story-section-content-subtitle"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Our Story
                  </p>
                  <h2
                    className="story-section-content-title"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    Balanced Medical Care Treatment Platform
                  </h2>
                  <p
                    className="story-section-content-description"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    At Balanced, we prioritise a compassionate and innovative
                    delivery of healthcare, tailored specifically to you. Our
                    team combines advanced medical technology with a deep
                    commitment to caring for each individual, tailoring every
                    treatment plan to meet unique health goals and needs.
                    <br />
                    <br />
                    Whether you’re seeking support for recovery, long-term
                    wellness, or specific health enhancements, we’re here for
                    you at every step. From thorough diagnostics to
                    comprehensive treatment and follow-up, Balanced is dedicated
                    to providing a complete and seamless healthcare experience.
                    <strong>
                      {" "}
                      Our mission is to be your partner on a journey toward a
                      healthier, more vibrant life.
                    </strong>
                  </p>
                  <Link
                    to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                    onClick={scrollToTop}
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    {" "}
                    <button className="ctabtn story-section-btn">
                      Join Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="features-section">
          <div className="container">
            <h2
              className="features-section-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Why Choose Us
            </h2>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="features-card h-100">
                  <div className="features-card-body">
                    <img
                      src={illustartion1}
                      alt="Health Icon"
                      className="features-card-icon mb-3"
                      style={{ width: "70px", height: "70px" }}
                      data-aos="fade-up"
                      data-aos-duration="800"
                    />
                    <h5
                      className="features-card-title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Your Wellness, Our Priority
                    </h5>
                    <p
                      className="features-card-text"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      We’re fully committed to supporting your health journey
                      with care that goes beyond the basics. By focusing on a
                      holistic approach, we design treatment plans that consider
                      every aspect of your lifestyle, ensuring your path to
                      optimal health is both comprehensive and personalized.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="features-card h-100">
                  <div className="features-card-body">
                    <img
                      src={illustartion2}
                      alt="Honest Icon"
                      className="features-card-icon mb-3"
                      style={{ width: "70px", height: "70px" }}
                      data-aos="fade-up"
                      data-aos-duration="800"
                    />
                    <h5
                      className="features-card-title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Transparency at Every Step
                    </h5>
                    <p
                      className="features-card-text"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Our team believes in open, honest communication. We keep
                      you informed about your treatment progress, making sure
                      you’re comfortable and empowered at each stage. Building
                      trust through transparency is our way of ensuring you feel
                      confident and supported.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="features-card h-100">
                  <div className="features-card-body">
                    <img
                      src={illustartion3}
                      alt="Personalized Icon"
                      className="mb-3 features-card-icon"
                      style={{ width: "70px", height: "70px" }}
                      data-aos="fade-up"
                      data-aos-duration="800"
                    />
                    <h5
                      className="features-card-title"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Tailored Care, Just for You
                    </h5>
                    <p
                      className="features-card-text"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      Every individual’s health journey is unique. Our approach
                      is focused on understanding your specific goals and needs
                      to provide personalised treatment plans that are designed
                      for the best possible outcomes in your well-being.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="callToAction_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <CallToAction />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
