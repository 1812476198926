import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

/**
 * HowitworksCard component that displays a step-by-step process of how the service works.
 * The component consists of animated cards that describe each step in the process
 **/

function HowitworksCard() {
  return (
    <div className="howItWorks_Card">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="howItWorks_card_img"></div>
            </div>
            <div className="col-lg-6 process_column">
              <div className="process">
                <div
                  className="process_line"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <img
                    src={require("..//assets/images/workProcess.PNG")}
                    alt=""
                  />
                </div>
                <div
                  className="process_card"
                  data-aos="fade-up"
                  data-aos-duration="820"
                >
                  <div className="details_wrapper">
                    <h4 className="process_name">Register</h4>
                    <p className="process_description">
                      Sign up as a Balanced patient to start your journey
                      towards improved wellbeing.
                    </p>
                  </div>
                </div>
                <div
                  className="process_card"
                  data-aos="fade-up"
                  data-aos-duration="830"
                >
                  <div className="details_wrapper" data-aos="fade-up">
                    <h4 className="process_name">
                      Complete the Health Questionnaire
                    </h4>
                    <p className="process_description">
                      Fill out our brief health questionnaire to assist us in
                      gauging your needs.
                    </p>
                  </div>
                </div>

                <div
                  className="process_card"
                  data-aos="fade-up"
                  data-aos-duration="840"
                >
                  <div className="details_wrapper">
                    <h4 className="process_name">Telehealth Consultation</h4>
                    <p className="process_description">
                      Connect with one of our registered healthcare
                      professionals to discuss your health goals and treatment
                      options.
                    </p>
                  </div>
                </div>
                <div
                  className="process_card"
                  data-aos="fade-up"
                  data-aos-duration="840"
                >
                  <div className="details_wrapper">
                    <h4 className="process_name">Place Your Order</h4>
                    <p className="process_description">
                      Once your plan is customised, you can place an order for
                      the recommended treatment program, carefully selected by
                      our expert team of doctors.
                    </p>
                  </div>
                </div>
                <div
                  className="process_card"
                  data-aos="fade-up"
                  data-aos-duration="850"
                >
                  <div className="details_wrapper">
                    <h4 className="process_name">Ongoing Support</h4>
                    <p className="process_description">
                      Collaborate with our team for continued guidance and
                      adjustments as needed via the patient dashboard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowitworksCard;
