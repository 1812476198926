import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../Api/AuthApi";
import getBaseUrl from "../Api/BaseUrl";

/**
 * Footer Component
 *
 **/

function Footer() {
  const [treatmentCards, setTreatmentCards] = useState([]);
  const [authState, setAuthState] = useState(() =>
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY))
  );
  const isAuthenticated = authState && authState.isAuthenticated === true;
  const authRedirectUrl = `${getBaseUrl()}/patient/treatment-plans/create`;
  const navigate = useNavigate();

  // Function to smoothly scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const fetchTreatmentCards = async () => {
      try {
        const response = await api.get("/api/treatments");

        setTreatmentCards(response.data.data);
      } catch (error) {
        console.error("Error fetching treatments:", error);
        // navigate("/page/error");
      }
    };

    fetchTreatmentCards();
  }, [navigate]);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedAuthState = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY));
      setAuthState(updatedAuthState);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const formatTitleForURL = (title) => {
    return title.replace(/ /g, "-").replace(/&/g, "and").replace(/or/g, "or");
  };
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="footer">
            <div className="row footer-top">
              <div className="col-md-3 footer-column mb-5">
                <img
                  src={require("..//assets/images/balanced-logo-white.png")}
                  alt="Logo"
                  className="footer-logo"
                />
                <ul className="footer-column-list">
                  <li>
                    <a href="mailto:support@balanced.com.au">
                      support@balanced.com.au
                    </a>
                  </li>
                  <li>
                    <a href="tel:+615570890234">+61 5570 8902 34</a>
                  </li>
                </ul>
                <div className="socials">
                  <a
                    href="https://www.facebook.com/balanced"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("..//assets/images/facebookIcon.png")}
                      alt="Facebook"
                      className="facebook-icon"
                    />
                  </a>
                  <a href="https://balanced" target="_blank" rel="noreferrer">
                    <img
                      src={require("..//assets/images/instaIcon.png")}
                      alt="Instagram"
                      className="insta-icon"
                    />
                  </a>
                </div>
              </div>

              <div className="col-md-3 footer-column mb-4">
                <h5 className="footer-column-title">What We Treat</h5>
                <ul className="footer-column-list">
                  {treatmentCards.map((treatment) => (
                    <li key={treatment.id}>
                      <Link
                        to={
                          isAuthenticated
                            ? authRedirectUrl
                            : `/questionnaire/${formatTitleForURL(
                                treatment.name
                              )}/${treatment.id}`
                        }
                        onClick={scrollToTop}
                      >
                        {treatment.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-3 footer-column mb-4">
                <h5 className="footer-column-title">Quick Links</h5>
                <ul className="footer-column-list">
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-story" onClick={scrollToTop}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/how-it-works" onClick={scrollToTop}>
                      How It Works{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-doctors" onClick={scrollToTop}>
                      Doctors
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" onClick={scrollToTop}>
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 footer-column mb-4">
                <h5 className="footer-column-title">Support</h5>
                <ul className="footer-column-list">
                  <li>
                    <Link
                      to="/contact-us"
                      onClick={scrollToTop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/support"
                      onClick={scrollToTop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/terms-and-conditions"
                      onClick={scrollToTop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms And Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      onClick={scrollToTop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/refund-policy"
                      onClick={scrollToTop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Refund Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <p className="copyrights">
            Copyrights &copy; Balanced. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
