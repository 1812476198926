import React from "react";
import FAQCard from "./FaqCard";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

/**
 * Faq Section Component
 *
 * This component renders the FAQ section of the website, displaying common questions and answers about the platform.
 * It includes a title, a short description, and a list of FAQ items (questions and answers) rendered using the `FAQCard` component.
 *
 * Props:
 * - FaqData: An array of question-answer pairs representing the frequently asked questions.
 */

function Faq() {
  const FaqData = [
    {
      question: "What is Balanced?",
      answer:
        "Balanced is a telehealth platform offering accessible, premium and customised peptide care treating a variety of health and wellness areas such as anti-aging - muscle vitality - cognitive enhancement - sleep - hair loss - libido enhancement -  skincare and immunity.",
    },
    {
      question: "What do we offer?",
      answer:
        "Our personalised solutions, including prescription and non-prescription peptide treatments, are tailored to your unique needs.",
    },
    {
      question: "How do I start?",
      answer:
        "Getting started is simple: Complete our online intake form to create an account and book your free Telehealth assessment. Share your health goals and medical history with a clinician. Following your consultation, our expert team of doctors will determine your eligibility, tailor a treatment plan fit for your needs.Now, you’re all set and ready to order your first round of treatment on your journey of health and wellbeing! ",
    },
    {
      question: "Is there a fee for a consultation with a prescriber?",
      answer:
        "No - here at Balanced we believe proactive healthcare should be accessible to all. Enjoy initial and continuous support, check-ins with your clinician and more free of charge as part of your healthcare journey.",
    },
    {
      question: "Where is Balanced available?",
      answer:
        "Balanced is currently available online and delivering to all states and territories, treating Australians from the comfort of their own home.",
    },
    {
      question: "How does shipping work?",
      answer:
        "Once your order is placed, your script is sent to one of our partner pharmacies to be processed - at which time you’ll receive an email with tracking information. Log in to your account anytime to track your package under 'Orders.' Do I need a prescription? Certain peptides require a prescription from a licensed practitioner. Our clinicians will determine if a prescription is necessary once your full assessment is completed by our doctors. ",
    },
  ];

  // Smooth scroll to top of the page when clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="faq_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 col-md-5 mb-5">
                <div className="left_section">
                  <h4 className="left_title" data-aos="fade-up">
                    Get Answers To All <span>Your Question</span>
                  </h4>
                  <p className="left_description" data-aos="fade-up">
                    Here you can find answers to all your question,feel free to
                    contact us if you didn’t find the answers to your questions
                    <Link
                      to="/contact-us"
                      className="link"
                      onClick={scrollToTop}
                    >
                      {" "}
                      here.
                    </Link>
                  </p>
                  <Link
                    to="/contact-us"
                    data-aos="fade-up"
                    onClick={scrollToTop}
                  >
                    <button className="ctaBtn contactUs_Btn">Contact Us</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-7">
                {FaqData.map((Faq, index) => (
                  <div
                    className="faq_card_wrapper"
                    key={index}
                    data-aos="fade-up"
                  >
                    <FAQCard question={Faq.question} answer={Faq.answer} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
