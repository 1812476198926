import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getBaseUrl from "../Api/BaseUrl";

/**
 * CallToAction Component
 *
 * This component provides a call-to-action section that prompts users
 * to either take a quiz or navigate to contact form. The section also
 * uses AOS (Animate on Scroll) for fading animation effects.
 */

function CallToAction() {
  const [authState, setAuthState] = useState(() =>
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY))
  );
  const isAuthenticated = authState && authState.isAuthenticated === true;
  const authRedirectUrl = `${getBaseUrl()}/patient/treatment-plans`;

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedAuthState = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_USER_AUTH_KEY)
      );
      setAuthState(updatedAuthState);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  // Initialize AOS animation library
  useEffect(() => {
    AOS.init();
  }, []);

  // Function to smoothly scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="callToAction_Div">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="callToAction_content">
                  <h6 className="callToAction_title" data-aos="fade-up">
                    Ready to Take the Next Step Toward a{" "}
                    <span>Healthier Lifestyle?</span>
                  </h6>

                  <div className="callToAction_btn">
                    <Link
                      to={isAuthenticated ? authRedirectUrl : `/quizzes`}
                      onClick={scrollToTop}
                    >
                      <button className="cta getStarted_btn">
                        {isAuthenticated
                          ? "Check Your Treatment"
                          : "Take your quiz"}
                      </button>
                    </Link>
                    <Link to="/contact-us" onClick={scrollToTop}>
                      <button className="cta login_btn">Contact Us</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
