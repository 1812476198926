import axios from "axios";

// Create an Axios instance with a predefined configuration
const api = axios.create({
  baseURL: window.location.port === "8443" ? process.env.REACT_APP_URL_8443 : process.env.REACT_APP_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.defaults.withXSRFToken = true;


export default api;