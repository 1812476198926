import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";

/**
 * login page
 * It allows users to log in by entering their email and password.
 * The page includes email and password validation, and upon successful login,
 * the user is navigated to the dashboard. It also logs key user events such as
 * login attempts, validation errors, and successful logins to the server.
 * The Helmet component is used to manage the document head for SEO purposes.
 */

const Login = () => {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [backendError, setBackendError] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false); // For sending code to email
  const [isCodeSent, setIsCodeSent] = useState(false); // To toggle between password and code input
  const [code, setcode] = useState(""); // For the code input after sending the email
  const [isCodeError, setIsCodeError] = useState(""); // To handle error for code validation
  const [resendCodeLoading, setresendCodeLoading] = useState(false); // To handle resend code loading
  const [identifier, setIdentifier] = useState("");

  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?:\+61|0)[2-478](?:[ -]?[0-9]){8}$/;

  const navigate = useNavigate();

  const validateEmailOrPhone = () => {
    if (!identifier) {
      setEmailOrPhoneError("Email or phone number is required."); // Set error if email is empty
      return false;
    } else if (!emailRegex.test(identifier) && !phoneRegex.test(identifier)) {
      setEmailOrPhoneError("Invalid email or phone number format."); // Set error if both formats are invalid
      return false;
    } else {
      setEmailOrPhoneError(""); // Clear the error if email is valid
      return true;
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required."); // Set error if password is empty
      return false;
    } else {
      setPasswordError(""); // Clear the error if password is valid
      return true;
    }
  };
  // Function to handle form submission method 2
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmailOrPhoneValid = validateEmailOrPhone();
    const isPasswordValid = validatePassword();

    const isEmail = emailRegex.test(identifier);

    // Prepare payload based on input type
    const payload = isEmail ? { email: identifier, password } : { phone: identifier, password };
    if (isEmailOrPhoneValid && isPasswordValid) {
      setLoading(true);
      try {
        await api.get("/sanctum/csrf-cookie");

        //Send login request with email and password
        const response = await api.post("/login", payload);
        const { panel } = response.data;

        // Redirect to the panel URL
        window.location.href = panel.url;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Handle 422 error (unprocessable content) - credentials error
          const errorMessage =
            error.response.data.errors?.email?.[0] ||
            "Invalid credentials. Try again.";
          setBackendError(errorMessage);
        } else {
          console.log(error);
          setBackendError("Server error. Please try again.");
          navigate("/page/error");
        }
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  };
  const validateCode = () => {
    if (!code) {
      setIsCodeError("Code is required."); // Set error if code is empty
      return false;
    } else {
      setIsCodeError(""); // Clear the error if code is valid
      return true;
    }
  };

  // Function to send a code to the email
  const sendCodeToEmailOrPhone = async () => {
    setCodeLoading(true); // Start loading for sending code
    if (!identifier) {
      setBackendError("Email or phone number is required.");
      setCodeLoading(false);
      return;
    }
  
    // Determine whether identifier is an email or phone
    const isEmail = emailRegex.test(identifier);
    const isPhone = phoneRegex.test(identifier);
  
    if (!isEmail && !isPhone) {
      setBackendError("Please enter a valid email or phone number.");
      setCodeLoading(false);
      return;
    }

    // Prepare payload based on input type
    const payload = isEmail ? { email: identifier } : { phone: identifier };
  
    try {
      setBackendError("");
      await api.get("/sanctum/csrf-cookie");
      await api.post("/send-code", payload);
      setIsCodeSent(true); // Show the code input field after sending the code
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errorMessage =
          error.response.data.errors?.email?.[0] ||
          error.response.data.errors?.phone?.[0] ||
          "Invalid request.";
        setBackendError(errorMessage);
      } else {
        setBackendError("Server error. Please try again.");
        navigate("/page/error");
      }
    } finally {
      setCodeLoading(false);
    }
  };
  
  // Function to send a code to the email
  const resendCodeToEmailOrPhone = async () => {
    if (!identifier) {
      setBackendError("Email or phone number is required.");
      return;
    }
  
    const isEmail = emailRegex.test(identifier);
    const isPhone = phoneRegex.test(identifier);
  
    if (!isEmail && !isPhone) {
      setBackendError("Invalid email or phone number.");
      return;
    }
  
    const payload = isEmail ? { email: identifier } : { phone: identifier };
  
    try {
      setBackendError("");
      setresendCodeLoading(true); // Start loading for resend code
      await api.get("/sanctum/csrf-cookie");
      await api.post("/resend-code", payload);
      setBackendError("Code sent successfully.");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errorMessage =
          error.response.data.errors?.email?.[0] ||
          error.response.data.errors?.phone?.[0] ||
          "Invalid request.";
        setBackendError(errorMessage);
      } else {
        setBackendError("Server error. Please try again.");
      }
    } finally {
      setresendCodeLoading(false);
    }
  };
  

  // Function to handle form submission for code validation
  const handleCodeSubmit = async (e) => {
    e.preventDefault();
  
    if (!identifier) {
      setBackendError("Email or phone number is required.");
      return;
    }
  
    const isEmail = emailRegex.test(identifier); // Check if the identifier is an email
    const isCodeValid = validateCode();
  
    if (isCodeValid) {
      try {
        setLoading(true);
        setCodeLoading(true); // Start loading for validating the code
        await api.get("/sanctum/csrf-cookie");
  
        // Construct payload dynamically
        const payload = isEmail
          ? { email: identifier, code }
          : { phone: identifier, code };
        // Send code validation request
        const response = await api.post("/validate-code", payload);
  
        const { panel } = response.data;
        // Redirect to the panel URL after successful code validation
        window.location.href = panel.url;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const errorMessage =
            error.response.data.errors?.email?.[0] ||
            error.response.data.errors?.phone?.[0] ||
            "Invalid code.";
          setBackendError(errorMessage);
        } else {
          setBackendError("Server error. Please try again.");
          navigate("/page/error");
        }
      } finally {
        setTimeout(() => {
          setCodeLoading(false); // End loading for code validation
          setLoading(false); // End loading for login
        }, 1000);
      }
    }
  };
  
  


  return (
    <div className="login-wrapper">
      <Helmet>
        <title>Balanced | Login To Dashboard</title>
      </Helmet>
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo-white.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <p className="login-body-title">Welcome Back</p>
              <form onSubmit={isCodeSent ? handleCodeSubmit : handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email or Phone*</label>
                  <input
                    type="text"
                    className={`form-control ${
                      emailOrPhoneError ? "is-invalid InputError" : ""
                    }`}
                    id="identifier"
                    placeholder="Enter Your Email or Phone"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    disabled={isCodeSent} // Disable email field once the code is sent
                  />
                  {emailOrPhoneError && <div className="invalid-feedback">{emailOrPhoneError}</div>}
                </div>
                {!isCodeSent && (
                  <>
                    <div className="form-group">
                      <label htmlFor="password">Password*</label>
                      <input
                        type="password"
                        className={`form-control ${
                          passwordError ? "is-invalid InputError" : ""
                        }`}
                        id="password"
                        placeholder="Enter Your Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordError && (
                        <div className="invalid-feedback">{passwordError}</div>
                      )}
                    </div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary btn-block login_pagebtn"
                    >
                      {loading ? "Logging in..." : "Login via password"}
                    </button>
                    <button
                      type="button"
                      onClick={sendCodeToEmailOrPhone}
                      className="btn btn-secondary btn-block"
                      disabled={loading || codeLoading} // Disable if loading or sending code
                    >
                      {codeLoading ? "Sending..." : "Login via code"}
                    </button>

                  </>
                )}
                {isCodeSent && (
                  <>
                    <div className="form-group">
                      <label htmlFor="code">Enter Code*</label>
                      <input
                        type="text"
                        className={`form-control ${isCodeError ? "is-invalid InputError" : ""}`}
                        id="code"
                        placeholder="Enter the code sent to your email"
                        value={code}
                        onChange={(e) => setcode(e.target.value)}
                      />
                      {isCodeError && <div className="invalid-feedback">{isCodeError}</div>}
                    </div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary btn-block"
                    >
                      {loading ? "Validating..." : "Login"}
                    </button>
                    <button
                      type="button"
                      onClick={resendCodeToEmailOrPhone}
                      className="btn btn-secondary btn-block"
                      disabled={loading || resendCodeLoading} // Disable if loading or sending code
                    >
                      {resendCodeLoading ? "Sending again..." : "Send another code"}
                    </button>

                  </>
                )}
              </form>
              <div className="login-footer">
                <p>
                  Not A Member? <Link to="/quizzes">Sign Up</Link>
                </p>
                <p>
                  <Link to="/users/password/new">Forgot Password?</Link>
                </p>
              </div>
              {backendError && (
                <div className="alert alert-danger">{backendError}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
